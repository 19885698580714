import { ContentCopy, CopyAll, Copyright } from '@mui/icons-material';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { Spinner, TableRowEmpty, VideoAVPlayer } from 'components';
import { API_URL } from 'env';
import { enqueueSnackbar } from 'notistack';
import { Fragment, useRef, useState } from 'react';
import { fileService, queryClient } from 'services';
import { getIframeCode } from 'utils/common';

const Home = () => {
  const [loading, setLoading] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [linkPreview, setLinkPreview] = useState('');

  const { data, isLoading } = useQuery({
    queryKey: ['fileService.getFiles'],
    queryFn: () => fileService.getFiles(),
  });

  const playerRef = useRef<HTMLVideoElement>(null);

  const handleChangeFile = (event: any) => {
    const [file] = event.target.files;
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);
    setLoading(true);
    fileService.uploadFile(formData).finally(() => {
      event.target.value = null;
      setLoading(false);
      queryClient.invalidateQueries({ queryKey: ['fileService.getFiles'] });
    });
  };

  return (
    <Fragment>
      <input type='file' disabled={isLoading} accept='video/*' onChange={handleChangeFile} />

      {loading && (
        <Box sx={{ width: '100%', marginY: 2 }}>
          <LinearProgress />
        </Box>
      )}

      <Spinner loading={loading}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Đường dẫn</TableCell>
                <TableCell>Trạng thái</TableCell>
                <TableCell>Người tạo</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((item) => {
                return (
                  <TableRow key={item.id}>
                    <TableCell>{item.path && `${API_URL}/files/${item.path}`}</TableCell>
                    <TableCell>{item.status}</TableCell>
                    <TableCell>{item.createBy.name}</TableCell>
                    <TableCell>
                      <div className='flex items-center justify-center space-x-2'>
                        <Tooltip title='Copy URL' placement='top'>
                          <IconButton
                            onClick={() => {
                              enqueueSnackbar('Đã copy', { variant: 'success' });
                              navigator.clipboard.writeText(
                                `${window.location.protocol + '//' + window.location.host}/videos/${item.id}`,
                              );
                            }}
                          >
                            <ContentCopy />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title='Preview' placement='top'>
                          <IconButton
                            onClick={() => {
                              setOpenPreview(true);
                              setLinkPreview(`${API_URL}/files/${item.path}`);
                            }}
                          >
                            <Copyright />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title='Copy Iframe Code' placement='top'>
                          <IconButton
                            onClick={() => {
                              enqueueSnackbar('Đã copy', { variant: 'success' });
                              navigator.clipboard.writeText(
                                getIframeCode(
                                  `${window.location.protocol + '//' + window.location.host}/videos/${item.id}`,
                                ),
                              );
                            }}
                          >
                            <CopyAll />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
              <TableRowEmpty visible={!isLoading && data?.length === 0} />
            </TableBody>
          </Table>
        </TableContainer>
      </Spinner>

      <Dialog open={openPreview} onClose={() => setOpenPreview(false)} maxWidth='md'>
        <DialogTitle>Preview</DialogTitle>
        <DialogContent>
          <VideoAVPlayer
            playerRef={playerRef}
            src={linkPreview}
            autoPlay={false}
            controls={true}
            width='100%'
            height='auto'
          />
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default Home;
