import { client } from './axios';

const uploadFile = (body: FormData): Promise<FileRecordType> => client.post(`/v1/files/upload`, body);
const getVideo = ({ id }: { id: string }): Promise<FileRecordType> => client.get(`/v1/files/${id}`);
const getFiles = (): Promise<FileRecordType[]> => client.get(`/v1/files`);

const fileService = {
  uploadFile,
  getVideo,
  getFiles,
};
export default fileService;
