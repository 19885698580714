import { client } from './axios';

const login = (body: LoginBody): Promise<LoginResponse> => client.post(`/v1/auth/login`, body);
const refreshToken = (body: RefreshTokenBody): Promise<LoginResponse> => client.post(`/v1/auth/refresh-tokens`, body);

const authService = {
  login,
  refreshToken,
};
export default authService;
