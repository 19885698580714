import { useQuery } from '@tanstack/react-query';
import { Spinner, VideoAVPlayer } from 'components';
import { API_URL } from 'env';
import { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { fileService } from 'services';

const VideoPlayer = () => {
  const { id: videoId } = useParams();

  const playerRef = useRef<HTMLVideoElement>(null);

  const { data: dataVideo, isLoading } = useQuery({
    queryKey: ['fileService.getVideo', videoId],
    queryFn: () => fileService.getVideo({ id: videoId! }),
    refetchInterval: (data) => (data.state.data?.status === 'PROCESSING' ? 5000 : false),
  });

  return (
    <div>
      {dataVideo?.status === 'READY' ? (
        <VideoAVPlayer
          playerRef={playerRef}
          src={`${API_URL}/files/${dataVideo?.path}`}
          autoPlay={false}
          controls={true}
          width='100%'
          height='100%'
        />
      ) : (
        <Spinner loading={dataVideo?.status === 'PROCESSING' || isLoading} />
      )}
    </div>
  );
};

export default VideoPlayer;
